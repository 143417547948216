<template>
  <div class="auth-widget--min-height flex flex-col gap-10 md:pt-10">
    <UiDialogHeader class="mb-0 flex flex-col gap-4 p-0">
      <UiDialogTitle class="text-2xl font-semibold">{{ t("title") }}</UiDialogTitle>
      <UiDialogDescription class="text-sm/4 font-light text-black/50">{{
        t("description")
      }}</UiDialogDescription>
      <b class="block font-medium">{{ formState.email }}</b>
    </UiDialogHeader>

    <form
      class="gap-inherit flex flex-col"
      novalidate
      @submit.prevent="verifyMutation.mutate({ code: state.verificationCode, ...formState })"
    >
      <div class="flex flex-col gap-4">
        <UiInput
          v-bind="registerField('verificationCode')"
          :errors="getErrorMessage('verificationCode')"
          :placeholder="t('verification_code')"
          maxlength="6"
          size="adaptive"
          pattern="[0-9]*"
          :classes="{ label: 'text-muted-foreground font-semibold' }"
          outlined
        />

        <div class="flex items-center">
          <i18n-t class="font-light text-black/50" tag="span" keypath="code_not_sent">
            <template #resend>
              <UiButton
                class="h-fit p-0 text-base font-medium text-inherit underline"
                variant="link"
                :disabled="resendMutation.isSuccess.value || resendMutation.isPending.value"
                @click="resendMutation.mutate({ email: formState.email })"
              >
                {{ resendMutation.isSuccess.value ? t("sent") : t("resend") }}
              </UiButton>
            </template>
          </i18n-t>

          <UiTransition>
            <UiSpinner class="ms-2" v-if="resendMutation.isPending.value" size="xs" />
          </UiTransition>
        </div>
      </div>

      <UiButton
        class="mt-auto"
        type="submit"
        :disabled="!eagerValid"
        :loading="verifyMutation.isPending.value"
      >
        {{ t("submit_cta") }}
      </UiButton>
    </form>

    <Teleport v-if="isMounted" to="#auth-widget-nav-slot" :disabled="!isMobile">
      <UiButton
        class="md:text-primary group h-fit max-h-6 w-fit p-0 text-base/6 font-medium text-black/50"
        variant="link"
        @click="navigateBack({ fallback: 'signup' })"
      >
        <LucideChevronLeft
          class="transition-all group-hover:-translate-x-1 rtl:rotate-180 rtl:group-hover:translate-x-1"
          :size="20"
        />
        {{ $t("common.back") }}
      </UiButton>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { useMutation } from "@tanstack/vue-query"
import { type HTTPError } from "ky"
import { maxLength, minLength, nonEmpty, object, pipe, string } from "valibot"

const { formState, clearFormState } = useAuthManager()
const { navigateBack, handleSuccess } = useLogin()
const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })
const { isMobile } = useDisplay()
const isMounted = useMounted()
const popup = usePopup()
const { executeReCaptcha } = useReCaptchaHandler()

const { state, eagerValid, registerField, getErrorMessage, setFieldErrors } = useForm(
  object({
    verificationCode: pipe(
      string(t("errors.missing")),
      nonEmpty(t("errors.missing")),
      minLength(6, t("errors.min_length")),
      maxLength(6, t("errors.min_length"))
    ),
  })
)

const verifyWithRecaptcha = async (credentials: VerifyCredentials) => {
  const recaptchaToken = await executeReCaptcha(RecaptchaAction.VERIFY)
  return AuthService.verifyUserWithRecaptcha(credentials, recaptchaToken)
}

const verifyMutation = useMutation({
  mutationFn: verifyWithRecaptcha,
  onSuccess: () => {
    handleSuccess()
    clearFormState()
  },
  onError: handleVerifyApiError,
})

const resendWithRecaptcha = async (credentials: ResendVerificationCredentials) => {
  const recaptchaToken = await executeReCaptcha(RecaptchaAction.RESEND_VERIFICATION)
  return AuthService.resendVerificationWithRecaptcha(credentials, recaptchaToken)
}

const resendMutation = useMutation({
  mutationFn: resendWithRecaptcha,
  onSuccess: () => {
    state.verificationCode = ""
  },
  onError: handleResendApiError,
})

function handleVerifyApiError(error: HTTPError) {
  switch (error?.response.status) {
    case 400:
      return setFieldErrors("verificationCode", t("errors.verification_failed"))

    default:
      return setFieldErrors("verificationCode", $t("errors.something_wrong.text"))
  }
}

function handleResendApiError(error: HTTPError) {
  switch (error?.response.status) {
    case 400:
      return popup.open({
        title: $t("errors.something_wrong.title"),
        content: $t("errors.something_wrong.text"),
        type: "alert",
      })
  }
}
</script>

<i18n lang="json">
{
  "he": {
    "title": "אמת את האימייל שלך",
    "description": "שלחנו קוד אימות לכתובת:",
    "verification_code": "קוד אימות",
    "code_not_sent": "לא הגיע קוד? {resend}",
    "resend": "שלחו שוב",
    "sent": "נשלח",
    "submit_cta": "יצירת פרופיל",
    "errors": {
      "min_length": "קוד אימות חייב להיות 6 ספרות",
      "missing": "קוד אימות חסר",
      "verification_failed": "תהליך האימות נכשל"
    }
  },
  "en": {
    "title": "Verify your email",
    "description": "We’ve sent a verification code to:",
    "verification_code": "Verification code",
    "code_not_sent": "Didn’t get a code? {resend}",
    "resend": "Resend",
    "sent": "Sent",
    "submit_cta": "Create profile",
    "errors": {
      "min_length": "Verification code must be 6 digits",
      "missing": "Verification code is missing",
      "verification_failed": "Verification failed"
    }
  }
}
</i18n>
