<template>
  <form class="auth-manager__form" novalidate @submit.prevent="onSubmit">
    <div class="flex flex-col gap-3 md:gap-4">
      <div v-if="isSignup" :class="cn([classNames.inputs, 'gap-inherit flex flex-row md:flex-col'])">
        <UiInput
          v-bind="registerField('firstName')"
          :errors="getErrorMessage('firstName')"
          hide-details
          :placeholder="$t('authentication.common.inputs.first_name')"
          :disabled="loading"
          required
          autocomplete="given-name"
          type="text"
          maxlength="80"
          size="adaptive"
          :classes="{ root: 'flex-1', label: 'text-muted-foreground font-semibold' }"
        />

        <UiInput
          v-bind="registerField('lastName')"
          :errors="getErrorMessage('lastName')"
          :placeholder="$t('authentication.common.inputs.last_name')"
          hide-details
          :disabled="loading"
          autocomplete="family-name"
          type="text"
          maxlength="80"
          size="adaptive"
          :classes="{ root: 'flex-1', label: 'text-muted-foreground font-semibold' }"
        />
      </div>

      <!-- Email & Password Inputs -->
      <div :class="['auth-manager__form-inputs gap-inherit', classNames.inputs]">
        <UiInput
          v-bind="registerField('email')"
          :errors="getErrorMessage('email')"
          :placeholder="$t('authentication.common.inputs.email')"
          :disabled="loading"
          required
          autocomplete="email"
          type="email"
          maxlength="80"
          size="adaptive"
          :classes="{ label: 'text-muted-foreground font-semibold' }"
        >
          <template #errors="{ error: err }">
            <i18n-t :keypath="err" scope="global" tag="span">
              <template v-if="isSignup" #login>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  @click.prevent="changeAuthPage('login')"
                  variant="link"
                >
                  {{ $t("authentication.buttons.login").toLowerCase() }}
                </UiButton>
              </template>

              <template #signup>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  v-if="!isSignup"
                  variant="link"
                  @click.prevent="changeAuthPage('signup')"
                >
                  {{ $t("authentication.buttons.signup") }}
                </UiButton>
                <span class="text-destructive">{{ $t("authentication.buttons.signup").toLowerCase() }} </span>
              </template>

              <template #reset_password>
                <UiButton
                  class="text-destructive h-0 p-0 font-normal underline"
                  variant="link"
                  @click.prevent="changeAuthPage('forgotpassword')"
                >
                  {{ $t("authentication.reset_password.buttons.send").toLowerCase() }}
                </UiButton>
              </template>
            </i18n-t>
          </template>
        </UiInput>

        <UiInput
          v-bind="registerField('password')"
          :errors="getErrorMessage('password')"
          :classes="{ label: 'text-muted-foreground font-semibold' }"
          :placeholder="$t('authentication.common.inputs.password')"
          required
          maxlength="80"
          size="adaptive"
          :disabled="loading"
          :autocomplete="isSignup ? 'new-password' : 'current-password'"
          :type="showPassword ? 'text' : 'password'"
        >
          <template #append>
            <UiButton
              v-if="!!state.password?.length"
              :key="showPassword ? 'hide' : 'show'"
              variant="link"
              type="button"
              size="sm"
              z-index="1"
              @click="showPassword = !showPassword"
            >
              {{ !showPassword ? $t("common.show") : $t("common.hide") }}
            </UiButton>
          </template>
        </UiInput>
      </div>
    </div>

    <div v-auto-animate :class="classNames.footer">
      <!-- Forgot password -->
      <div v-if="!isSignup" :class="['auth-manager__forgot mt-4 leading-none']">
        <a
          class="text-sm/6 font-light text-black/50 underline hover:cursor-pointer"
          tabindex="0"
          @click.prevent="changeAuthPage('forgotpassword')"
        >
          {{ $t("authentication.login.buttons.ghost_forgot_password") }}
        </a>
      </div>

      <UiBanner v-if="generalApiErrorMessage" :classes="{ root: 'mt-5' }" variant="error">
        <p class="gap-xxs text-sm font-light">{{ $t(`${generalApiErrorMessage}`) }}</p>
      </UiBanner>

      <UiButton
        :class="cn('mt-10 w-full text-base', classNames.cta)"
        :loading="loading"
        :disabled="!eagerValid"
        type="submit"
        :id="genClickId('auth', isSignup ? 'signup' : 'login', 'button')"
        :_title="$t('authentication.login.buttons.goto_finq')"
      >
        {{ isSignup ? $t("authentication.buttons.signup") : $t("authentication.login.buttons.goto_finq") }}
      </UiButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { cva } from "class-variance-authority"
import { check, email, maxLength, minLength, nonEmpty, nullish, object, pipe, string, union } from "valibot"

type TAuthFormField = keyof typeof state

interface RecaptchaConfig {
  enabled: boolean
  action?: string
}

interface AuthManagerClasses {
  root: ClassValue
  inputs: ClassValue
  cta: ClassValue
  footer: ClassValue
}

interface AuthManagerProps {
  recaptcha: RecaptchaConfig
  isSignup: boolean
  loading: boolean
  hideGhostLink: boolean
  classes: AuthManagerClasses
  variant: keyof (typeof authManagerVariants)["variant"]
}

const props = withDefaults(defineProps<AuthManagerProps>(), {
  recaptcha: () => ({ enabled: false }),
  isSignup: true,
  loading: false,
  hideGhostLink: false,
  classes: () => ({
    root: undefined,
    inputs: undefined,
    cta: undefined,
    footer: undefined,
  }),
  variant: undefined,
})

const emit = defineEmits<{
  (e: "onSubmit", formData: any): void
}>()

const authInputVariants = cva("flex flex-col", {
  variants: {
    variant: { ...authManagerVariants.variant, horizontal: "flex-row [&>div]:flex-1 gap-xxs pb-0" },
  },
})

const user = useUser()
const { t } = useI18n({ useScope: "global" })
const { changeAuthPage } = useLogin()
const { formState, clearApiError } = useAuthManager()
const apiError = useAuthApiError()
const showPassword = ref(false)

const { state, eagerValid, registerField, getErrorMessage, setFieldErrors } = useForm(buildFormObjectSchema())

// LifeCycle hooks
onMounted(() => {
  if (user.user.value?.email) formState.email = user.user.value?.email
})

onUnmounted(() => {
  clearApiError()
})

// Watchers
// sync between formState global reactive object and state from useForm composable
watch(
  () => state,
  () => {
    Object.assign(formState, state)
  },
  { deep: true }
)

// handle api error for specific fields
watch(
  () => apiError.error.value,
  () => handleApiError()
)

// handle general api error for global form
watch(
  () => eagerValid.value,
  (newEagerValue, oldEagerValid) => (oldEagerValid && !newEagerValue ? clearApiError() : null)
)

// Computed values
const classNames = computed(() => {
  return {
    root: cn(props.classes.root),
    footer: cn("mt-auto", props.classes.footer),
    cta: cn(props.classes.cta),
    inputs: cn(authInputVariants({ variant: props.variant, class: props.classes.inputs as any })),
  }
})

const generalApiErrorMessage = computed(() => {
  if (!apiError.error.value) return null

  const { field, message } = apiError.error.value
  const showGeneralError = !field && eagerValid.value

  return showGeneralError ? message : null
})

// AuthManager helpers
function buildFormObjectSchema() {
  const baseFormSchema = {
    email: pipe(
      string(t("authentication.common.inputs.validation_errors.email_required")),
      nonEmpty(t("authentication.common.inputs.validation_errors.email_required")),
      minLength(7, t("authentication.common.inputs.validation_errors.min_length", { minLength: 7 })),
      email(t("authentication.common.inputs.validation_errors.email_not_valid"))
    ),
  }

  const loginFormSchema = {
    ...baseFormSchema,
    password: pipe(
      string(t("authentication.common.inputs.validation_errors.password_required")),
      nonEmpty(t("authentication.common.inputs.validation_errors.password_required")),
      check(
        (input: string) => !props.isSignup || regexPatterns.password.test(input),
        t("authentication.common.inputs.validation_errors.password_hint")
      )
    ),
  }

  const signupFormSchema = {
    ...loginFormSchema,
    firstName: pipe(
      string(t("authentication.common.inputs.validation_errors.first_name_required")),
      nonEmpty(t("authentication.common.inputs.validation_errors.first_name_required")),
      maxLength(80, t("authentication.common.inputs.validation_errors.max_length", { maxLength: 80 }))
    ),
    lastName: union([
      nullish(string()),
      pipe(
        string(),
        maxLength(80, t("authentication.common.inputs.validation_errors.max_length", { maxLength: 80 }))
      ),
    ]),
  }

  const formSchema = props.isSignup ? signupFormSchema : loginFormSchema
  return object(formSchema)
}

function handleApiError() {
  if (!apiError.error.value) return

  const { field, message } = apiError.error.value
  if (!field) return

  return setFieldErrors(field as TAuthFormField, t(`${message}`))
}

// AuthManager events
const onSubmit = () => {
  emit("onSubmit", formState)
}
</script>

<style lang="scss" scoped>
.auth-manager {
  &__forgot {
    &-error {
      color: theme.$red-pink;

      a {
        font-weight: bold;
      }
    }
  }
}
</style>
